<template>
  <div class="model-page">
    <!-- banner -->
    <div class="model-page_banner">
      <div class="header container">
        <div class="name">{{ model.name }}</div>
        <div class="mainModel">{{ model.mainModel }}</div>
        <div class="intro">{{ model.intro }}</div>
        <div class="d-flex">
          <el-tag
            class="tag"
            v-for="(item, index) in model.label"
            :key="index"
            type="success"
            >{{ item }}</el-tag
          >
        </div>
      </div>
    </div>

    <!-- info -->
    <div class="model-page_info-box">
      <div class="box-item">
        <div class="key">所属公司</div>
        <div class="value">{{ model.organization }}</div>
      </div>

      <div class="box-item">
        <div class="key">参数规模</div>
        <div class="value">{{ model.scale||"未知" }}</div>
      </div>
      <div class="box-item">
        <div class="key">发布日期</div>
        <div class="value">{{ model.date ||"未知"}}</div>
      </div>

      <el-button type="primary" @click="jumpUrl(model.url)"
        >访问官网<i class="el-icon-arrow-right el-icon--right"></i
      ></el-button>
    </div>

    <!-- rate -->
    <div class="model-page_rate container">
      <div class="rate-header d-flex">
        <div class="sub-title bottom-line d-flex">
          <div class="gap"></div>
          <div>能力维度评分</div>
        </div>
        <!-- 维度筛选 -->
        <el-radio-group class="tabs" v-model="value"  @input="getData">
          <el-radio-button
            v-for="item in dimensionList"
            :key="item"
            :label="item"
           
          ></el-radio-button>
        </el-radio-group>
      </div>

      <!-- 中英文测集  -->
      <el-radio-group class="lang-radio" v-model="value1"  @input="getData" v-if="isTotal" >
        <el-radio-button
          v-for="item in langList"
          :key="item"
          :label="item"
        ></el-radio-button>
      </el-radio-group>

      <div class="echart-box d-flex">
        <!-- 雷达图 -->
        <RadarChart :echartsData="echartsData" v-if="isTotal" />
        <!-- 柱状图 -->
        <BarChart :echartsData="echartsData" v-if="isTotal" />
        <!-- 全局柱状图 -->
        <FullBarChart :echartsData="barData" v-if="!isTotal" />
      </div>
    </div>

    <!-- weight -->
    <!-- <div class="model-page_weight container">
      <div class="weight-header">
        <div class="sub-title bottom-line d-flex">
          <div class="gap"></div>
          <div>评分占比</div>
        </div>
      </div>
      <div class="echart-box"></div>
      <PieChart />
    </div> -->
  </div>
</template>

<script>
// 柱状图
import BarChart from "@/components/echarts/bar";
// 柱状图
import FullBarChart from "@/components/echarts/fullBar";

// 雷达图
import RadarChart from "@/components/echarts/radar";

// 占比图
// import PieChart from "@/components/echarts/pie";

import modelList from "@/assets/data/model/model.json";

export default {
  components: {
    BarChart,
    RadarChart,
    // PieChart,
    FullBarChart,
  },
  data() {
    return {
      value: "综合评分",
      value1: "全部评测集",
      langList: ["全部评测集", "中文评测集", "英文评测集"],
      dimensionList: [],
      model: {},
      isTotal: true,//是否为综合评分测评
      //综合测评 图表数据
      echartsData: {
        name: "",
        data: [],
      },

      // 单个能力柱状图数据
      barData: {
        name: "",
        data: [],
      },
    };
  },
  created() {
    // 模型名称
    const name = this.$route.query.name;
    this.echartsData.name = name;
    this.barData.name = name;

    const model = modelList.models.find((item) => item.name === name);
    this.model = model;
    // 能力维度list
    this.dimensionList = model.details.eval_results.map(
      (item) => item.function
    );
    console.log("this.model", this.model);

    this.getData();
  },
  mounted() {},
  methods: {
  
    getData() {
      const activeDimension = this.value;
      const target = this.model.details.eval_results.find(
        (item) => activeDimension === item.function
      );
      this.isTotal = target.is_total;
      const englishValue = this.value1;
      // 判断是否为is_total 综合测评的数据
      if (target.is_total) {
        // 图表数据
        if (englishValue === "全部评测集") {
          this.echartsData.data = target.total_scores;
        } else if (englishValue === "中文评测集") {
          this.echartsData.data = target.chinese_scores;
          console.log('中文评测集')
        } else if (englishValue === "英文评测集") {
          console.log('英文评测集')

          this.echartsData.data = target.english_scores;
        }
      } else {
        // 单个能力维度的测试数据
        this.barData.data = target.dataset_scores;
      }
    },
    jumpUrl(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.model-page {
  .model-page_banner {
    background: url("~@/assets/images/model/header_detail.png");
    height: 440px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    .header {
      text-align: left;
      .name {
        font-weight: 400;
        font-size: 24px;
        color: #1d2129;
        line-height: 32px;
        margin-bottom: 12px;
        height: 32px;
      }
      .mainModel {
        height: 44px;
        font-weight: 550;
        font-size: 36px;
        color: #1d2129;
        line-height: 44px;
        margin-bottom: 24px;
      }

      .intro {
        font-weight: 400;
        font-size: 14px;
        color: #4e5969;
        line-height: 22px;
        margin-bottom: 24px;
      }
      .tag {
        border-radius: 12px;
        font-size: 12px;
        color: #4e5969;
        padding: 0 12px;
        margin-right: 9px;
        line-height: 28px;
        height: 28px;
      }
    }
  }

  .model-page_info-box {
    border: 1px solid #e5e6eb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px 12px 12px 12px;
    margin: 0 120px;
    background-color: #fff;
    transform: translateY(-50%);
    .box-item {
      display: flex;
      flex-direction: column;
      .key {
        font-weight: 400;
        font-size: 14px;
        color: #4e5969;
      }
      .value {
        font-weight: 500;
        font-size: 16px;
        color: #1d2129;
      }
    }
  }
  .sub-title {
    padding-bottom: 16px;
    font-weight: 500;
    font-size: 20px;
    color: #1d2129;
    .gap {
      width: 4px;
      height: 16px;
      background: #165dff;
      border-radius: 2px 2px 2px 2px;
      margin-right: 16px;
    }
  }
  .model-page_rate {
    margin-top: 32px;
    .rate-header {
      justify-content: space-between;
      border-bottom: 1px solid #e5e6eb;
      padding-bottom: 22px;
    }

    .lang-radio {
      margin-top: 30px;
    }

    .echart-box {
      margin-top: 32px;
      display: flex;
      justify-content: space-around;
    }
  }

  .model-page_weight {
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    .weight-header {
      width: 100%;
      border-bottom: 1px solid #e5e6eb;
      padding-bottom: 22px;
    }

    .echart-box {
      margin-top: 30px;
    }
  }
}
</style>
