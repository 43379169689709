<template>
  <div class="d-flex jc-center">
    <Chart :cdata="echartsData" />
  </div>
</template>
  
  <script>
import Chart from "./chart.vue";

export default {
  data() {
    return {
      cdata: {},
    };
  },
  components: {
    Chart,
  },
  props: {
    echartsData: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    console.log('echartsData',this.echartsData)
  },
  beforeDestroy() {
  },
  methods: {
  
  },
};
</script>
  
  <style lang="scss" scoped>
</style>