<template>
  <div>
    <Echart :options="options" id="bar" height="480px" width="500px"></Echart>
  </div>
</template>
  
  <script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {

      
      handler(newData) {
        const dataArr = newData.data.map((item) => Number(item.score).toFixed(2));
        const indicator = newData.data.map((item) => {
          return {
            name: item.function,
            max: 100,
            color: "#1D2129",
          };
        });
        this.options = {
          tooltip: {
            trigger: "item",
          },
          title: {},
          legend: {
            data: [newData.name],
            textStyle: {
              color: "#1D2129",
            },
          },
         
          radar: {
            // shape: 'circle',
            indicator: indicator,
            axisName: {
              show: true,
            },
          },
          series: [
            {
              // name: "Budget vs spending",
              type: "radar",
              label: {
                show: true,
              },
              areaStyle: {
                color: "rgba(190, 218, 255, 0.40)", // 设置区域颜色
              },
              data: [
                {
                  value:dataArr,
                  name: newData.name,
                },
              ],
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
  